import React from "react"
import retoureHeader from "../../../assets/images/products/netstorsys/retoure_header.svg"
import Sidebar from "./Sidebar"
import { Col, Container, Row } from "react-bootstrap"
import { Link } from "gatsby"
import Outlook from "../Outlook"

const Retoure = () => {
  return (
    <>
      <section className="services-details-area pt-100" style={{ marginBottom: "3em" }}>
        <Container className="mt-5">
          <Row>
            <Col lg={8} md={12}>
              <div className="services-details-image">
                <img src={retoureHeader} alt="Retouremanagement" />
              </div>

              <div className="">
                <span className="products-sub-title">Kunden mehr zurückgeben</span>
                <h1 className={"pt-3"}>Retouren mit NETSTORSYS </h1>
                <p>
                  Gefällt nicht, passt nicht, beschädigt, woanders günstiger gesehen: Retouren sind integraler
                  Bestandteil des B2C-Geschäfts.
                </p>
                <p>
                  Die höchste Retourenquote hat in Deutschland die Modebranche (bei Bestellungen auf Rechnung) von rund
                  55,65%, gefolgt von Consumer Electronics (18,6%) sowie Medien und Bücher (11,45%) (Quelle: &nbsp;
                  <Link
                    to={"http://www.retourenforschung.de/definition_statistiken-retouren-deutschland.html"}>retourenforschung.de</Link>)
                </p>
                <p>
                  Retouren können auf bis zu 70% des Absatzes anwachsen.
                </p>
                <p>
                  Retouren kosten Geld. Warum es nicht gleich in <b>mehr Kundenzufriedenheit, stärkere
                  Kundenbindung</b> und
                  wertvolle Retourendaten für <b>bessere Prozesse, Produkte und Services</b> investieren?
                </p>
                <h2>Schnell handeln und Vertrauen gewinnen</h2>
                <p>
                  Das leistungsfähige Retourenmanagement von NETSTORSYS ermöglicht es, Warenrücksendungen fehlerfrei,
                  zügig und kostensparend abzuwickeln. Wenn Kunden Waren zurückgeben, ist das die Chance, den perfekten
                  Eindruck dazulassen, Vertrauen und die nächste Bestellung zu gewinnen.
                </p>

                <h2>Kosten senken, unkompliziert retournieren</h2>
                <p>
                  NETSTORSYS beschleunigt und unterstützt mit automatisierten, intuitiven Nutzerinteraktionen die
                  Annahme, Prüfung, Wiederaufbereitung und strategische Wiedereinlagerung retournierter Ware, senkt
                  Bearbeitungszeit und Lagerkosten.
                </p>
                <p>
                  Retourenanfragen und Retourengründe lassen sich schnell erfassen und bearbeiten, Bestände
                  aktualisieren, Anliegen schnell und transparent lösen: durch eine schnelle Erstattung, Ersatzlieferung
                  oder transparente Ablehnung einer Retoure. Kunden haben ein unkompliziertes Rückgabeerlebnis.
                </p>
                <h2>Ready to go mit Shops und Carriern</h2>
                <p>
                  Das LVS ist nahtlos an Shop-Systeme und – über die Multicarrier-Versandsoftware <Link
                  to="/versandsoftware">NETVERSYS</Link> – an
                  Versanddienstleister angebunden. Aus den Daten entstehen wertvolle Reportings, die helfen, fehlerhafte
                  Produkte oder Schwachstellen bei Prozessen zu erkennen und künftig zu vermeiden.
                </p>
                <Outlook
                  text={"Mit NETSTORSYS können Unternehmen ihren Kunden einfach ein bisschen mehr zurückgeben."} />
              </div>
            </Col>

            <Col lg={4} md={12}>
              <Sidebar />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}

export default Retoure
